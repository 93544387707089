export default function Contact() {
  return (
    <div
      style={{
        margin: "150px auto 0",
        maxWidth: "680px",
        border: "1px dashed black",
        padding: "24px",
        lineHeight: "1.5",
      }}
    >
      Please send an email to <strong>sewing.statecollege@gmail.com</strong>{" "}
      with your specific request. <br /> You will receieve a response within
      24-48 business hours with a specific quote and instructions for dropping
      off your garment(s).
      <br />
      <br />
      In your email, please specify the <strong>
        type of garment
      </strong> and <strong>specific alteration service</strong> and{" "}
      <strong>any special requests </strong>needed.
      <br/><br/> 
      <a target="_blank" rel="noopener noreferrer" href="mailto:sewing.statecollege@gmail.com?subject=Alteration Request">
        <button type="button">Send Email</button>
      </a>
    </div>
  );
}
