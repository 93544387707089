// import logo from "./logo.svg";
import "./App.css";

import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "./Home";
import About from "./about";
import PriceSheet from "./pricesheet";
import Gallery from "./gallery";
import Contact from "./contact";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [open, setOpen] = useState(false);

  const toggleNavBar = () => {
    setOpen(!open);
    console.log(open);
  };

  return (
    <Router>
      <React.Fragment>
        <nav className={`navbar ${open ? 'open' : 'close'}`}>
          <Link className="navbar-logo" to="/">
            🪡 <strong>State College Tailor</strong>
          </Link>
            <div className="navbar-links">
              <ul>
                <li role="link">
                  <Link to="/">Home</Link>
                </li>
              </ul>
              <ul>
                <li role="link">
                  <Link to="/services" title="Our Tailoring Services">Services</Link>
                </li>
              </ul>
              <ul>
                <li role="link">
                  <Link to="/about" title="Our Tailoring Story">About</Link>
                </li>
              </ul>
              <ul>
                <li role="link">
                  <Link to="/gallery" title="Photos of Client Alterations">Gallery</Link>
                </li>
              </ul>
              <ul>
                <li role="link">
                  <Link to="/contact" title="Contact Our Tailor">Contact</Link>
                </li>
              </ul>
            </div>
            <Link to="/contact" className="navbar-button">
              <button>Book Alteration</button>
            </Link>
          <FontAwesomeIcon
            icon={faBars}
            inverse
            className="icon"
            onClick={toggleNavBar}
          />
        </nav>

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/services" element={<PriceSheet />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
      </React.Fragment>
    </Router>
  );
}

export default App;
