import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

//todo: create order form

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    if (domRef.current) {
      observer.observe(domRef.current);
    }

    return () => {
      if (domRef.current) {
        observer.unobserve(domRef.current);
      }
    };
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default function Home() {
  return (
    <div>
      <main>
        <section className="hero">
          <img
            src="/images/sewing-machine.png"
            alt="sewing machine"
            className="icon-img"
          />
          <h1 className="hero-message">Alterations starting at just $25.</h1>
          <p style={{ lineHeight: "1.5" }}>
            Simply <span className="highlight">pre-pin your garment</span> and{" "}
            <span className="highlight">hand it off</span>, hassle-free. <br />
            No appointments necessary.
          </p>
          <Link to={"/contact"}>
            <button>Alter My Cloths</button>
          </Link>
          <ul className="link">
            <li>
              or <a href="/services">Explore prices</a>
            </li>
          </ul>
        </section>

        <FadeInSection>
          <section className="value-props">
            <h2 className="section-title">Why Work With Me</h2>
            <div className="row">
              <div className="col">
                <h3>Quality</h3>
                <p>
                  Devoted to delivering the finest quality in every alteration.
                </p>
              </div>
              <div className="col">
                <h3>Convenience</h3>
                <p>
                  I will come to you at any public place at or around the Penn State
                  campus.
                </p>
              </div>
              <div className="col">
                <h3>Local</h3>
                <p>
                  Proudly rooted in and dedicated to serving the residents of
                  State College.
                </p>
              </div>
            </div>
            <ul className="link">
              <li>
                <a href="/about">About Your Tailor</a>
              </li>
            </ul>
          </section>
        </FadeInSection>

        <FadeInSection>
          <section className="how-to">
            <h2 className="section-title">How It Works</h2>
            <div className="row">
              <div className="col">
                <h3>Pin it</h3>
                <p>
                  Pin your garments, share measurements, or provide a
                  well-fitting sample for alterations.
                </p>
              </div>
              <div className="col">
                <h3>Hand it off</h3>
                <p>
                  We'll meet at a convenient public location for you to hand off
                  your garments.
                </p>
              </div>
              <div className="col">
                <h3>Get it back</h3>
                <p>
                  Expect your expertly altered garments back in your hands
                  within 5-7 business days, or even sooner with a rush order.
                </p>
              </div>
            </div>
            <Link to={"/contact"}>
              <button style={{ marginTop: "45px" }}>
                Order Your Alteration Today
              </button>
            </Link>
          </section>
        </FadeInSection>

        <FadeInSection>
          <section className="testimonials">
            <h1 className="section-title">What Clients Say</h1>
            <div>
              <div>
                <p>
                  "Aziza!! Thank you for the great job with my pants. I am very
                  pleased and will definitely be using you for many other
                  alterations. Welcome to Happy Valley."
                </p>
                <small>- Alan S. (on NextDoor)</small>
              </div>
              {/* <div>
        <p></p>
        <small></small>
      </div> */}
            </div>
            <ul className="link">
              <li>
                <a href="/gallery">View Work Samples</a>
              </li>
            </ul>
            <br /> <br />
          </section>
        </FadeInSection>
      </main>
      <footer>
        <small>&copy; Copyright 2024</small> <br /> <br />
        <a
          href="https://www.flaticon.com/free-icons/sewing-machine"
          title="sewing machine icon"
        >
          Sewing machine icon created by Freepik - Flaticon
        </a>
      </footer>
    </div>
  );
}
