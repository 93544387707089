export default function About() {
  return (
    <div
      style={{
        margin: "150px auto 0",
        maxWidth: "680px",
        border: "1px dashed black",
        padding: "24px",
        lineHeight: "1.5",
      }}
    >
      <p>
        Hi there! I'm Aziza, and I moved to State College in the Fall of 2023
        from Phoenix, Arizona.
      </p>
      <p>
        My journey into seamstressing started young, while I was still in middle
        school. Trained by an expert seamstress (a family friend) one summer in
        her shop. Since then, I've been constructing garments for all members of
        my family and all ages. In college, I got to experience working at a
        custom shop for the theatrical performances at Arizona State
        University's School of Music.
      </p>
      <p>
        But there's more to me than just stitches and seams. I'm a second
        generation American from Afghan refugee parents. I was the first and
        only woman on my mother's side to ever graduate from high school and
        then receive a college education. Coming from a traditional family, I
        finally moved away from home in 2023 to pursue my dreams of being an
        entrepreneur.
      </p>
      <p>
        I landed in State College, borrowed a sewing machine from a good friend
        and started my own tailoring business. As an entrepreneur, I also work
        on a digital healthcare company (improving access for the medically
        underserved) and as a part-time instructional designer at Penn State
        University (helping people work with data).
      </p>
      <p>
        By choosing to work with me you not only get the highest quality
        alterations, but you also support my entrepreneurial dreams!
      </p>
    </div>
  );
}
