export default function PriceSheet() {
    return (
        <div style={{margin: "150px auto 0", maxWidth: "680px", border: "1px dashed black", padding: "24px"}}>
            <table>
                <caption>Pants & Jeans</caption>
                <thead>
                    <tr>
                        <th>Service</th>
                        <th>Approximate Cost</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Hem (standard)</td>
                        <td>$25</td>
                    </tr>
                    <tr>
                        <td>Taper full leg</td>
                        <td>$35</td>
                    </tr>
                    <tr>
                        <td>Original hem</td>
                        <td>$35</td>
                    </tr>
                    <tr>
                        <td>Hem with cuff</td>
                        <td>$35</td>
                    </tr>
                    <tr>
                        <td>Hem with lining</td>
                        <td>$35</td>
                    </tr>
                    <tr>
                        <td>Slanted Hem</td>
                        <td>$35</td>
                    </tr>
                    <tr>
                        <td>Blind hem by hand</td>
                        <td>$35</td>
                    </tr>
                    <tr>
                        <td>Take in / out waist</td>
                        <td>$25</td>
                    </tr>
                    <tr>
                        <td>Zipper replacement</td>
                        <td>$25</td>
                    </tr>
                    <tr>
                        <td>Jean zipper replacement</td>
                        <td>$30</td>
                    </tr>
                    <tr>
                        <td>Repairs</td>
                        <td>Varies by severity</td>
                    </tr>
                    <tr>
                        <td><strong>Rush order (under 5 days)</strong></td>
                        <td>+ $10</td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <small><em>* Prices are subject to change. <br/> Contact <strong>sewing.statecollege@gmail.com</strong> for specific quotes.</em></small>
        </div>
    )
}